<template>
  <div class="eventBox">
    <div class="chat_top">
      {{ $t('home.Event')+$t('home.List') }}
      <!-- <span @click="goOrderDetail">{{ $t('home.seeMore') }}</span> -->
    </div>
    <div
      class="eventList"
      v-infinite-scroll="load"
      :infinite-scroll-distance="20"
      v-loading="loading"
    >
      <div
        v-for="(item, index) in eventList"
        :key="index"
        class="itemBox"
        :class="activeId == item.eventId ? 'active' : ''"
      >
        <member v-if="!item.isSystem" :info="item.createPerson" :size="48" />
        <i v-else class="iconfont avatar" :class="iconComputed(item)"></i>
        <div class="content" @click="jump(item)">
          <div class="content_user">
            <div v-if="!item.isSystem">
              <span class="active" v-if="!item.read" />
              {{ item.createPerson.nickName }}
              {{ $t("InformationCenter.posted") }}
              <span> {{ item.subject }}</span>
            </div>
            <div v-else>
              {{ systemName(item) }}
            </div>
            <p>
              NO. {{ item.ci || item.pi }}
            </p>
          </div>
          <div class="bottom">
            <conversation-bubble 
              v-if="!item.isSystem"
              maxWidth="50%"
              :item="{
                msg: item.content,
                strId: item.eventId
              }" 
            />
            <div class="conversationBubble" v-else>
              <div class="headerBox">
                <div class="left">
                  <member v-if="item.content.type != 4" :info="item.createPerson" :size="24" />
                  <div class="content" v-if="item.createPerson">
                    <span class="name" v-if="item.content.type != 4">{{ item.createPerson.nickName }}</span>
                    <span class="name" v-else>{{ $t('memberList.manage') }}</span>
                    <span class="behavior">{{ behaviorComputed(item) }}</span>
                    <span class="title">{{ titleTypeComputed(item) }}</span>
                  </div>
                </div>
                <div class="detail" v-if="item.content.type == 2 && item.content.status == 2" @click.stop="item.detailOpen = !item.detailOpen">
                  {{ $t("home.detailText") }}
                  <i :class="!item.detailOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                </div>
              </div>
              <div class="clockInContent" v-if="item.content.type == 3">
                <div class="text" v-if="orderProgress[item.content.oldProgress]">
                  {{ $t('order.orderStatusForm') }}
                  {{ orderProgress[item.content.oldProgress].key }}
                  {{ $t('order.change to') }}
                  <span>{{ orderProgress[item.content.newProgress].key }}</span>
                </div>
                <fileBlocks
                  v-for="(row, index) in item.content.urlList"
                  :key="index"
                  :embedUrl="row.savePath"
                  :embedId="row.fileId"
                  :ci="item.ci"
                >
                </fileBlocks>
              </div>
              <div class="changeContent" v-if="item.content.type == 1 && item.content.status == 2">
                <div class="item" v-for="(row, index) in item.content.data" :key="index">
                  <span>{{ $store.state.user.orderAlias == 0 ? row.fieldName : row.fieldName == 'PI号' || row.fieldName == 'PI number' ? $isOrderPITitle() : row.fieldName }}</span>
                  {{ $t('order.form') }}
                  {{ shipFieldValueComputed(row) }}
                  {{ $t('order.change to') }}
                  <span>{{ shipFieldValue1Computed(row) }}</span>
                </div>
              </div>
              <div class="shipContent" v-if="item.content.type == 2">
                <div v-if="item.content.status == 2">
                  <div class="itemTwo" v-for="(row, index) in item.content.data.filter(item => item.change)" :key="index+'two'">
                    <span>{{ row.fieldName }}</span>
                    {{ $t('order.form') }}
                    {{ shipFieldValueComputed(row) }}
                    {{ $t('order.change to') }}
                    <span>{{ shipFieldValue1Computed(row) }}</span>
                  </div>
                </div>
                <div class="shipBox" v-if="item.content.status == 1 || item.detailOpen">
                  <div class="item" v-for="(row, index) in item.content.data" :key="index">
                    <span>
                      {{ row.fieldName }}
                      <span class="tag" v-if="row.change">{{ $t('order.Change') }}</span>
                    </span>
                    <span>{{ shipFieldValueComputed(row) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <time>{{ item.updateTime }}</time>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { getEventList, eventRead } from "@/api/event";
import { pickerOptions } from "@/utils/utils";
export default {
  name: "eventList",
  components: {},
  inject: ["reload"],
  data() {
    return {
      activeId: null,
      data: {
        currentPage: 1,
        pageSize: 15,
      },
      totalPage: 0,
      eventList: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["eventUpdate", "orderProgress", "eventAllRead"]),
    i18n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    iconComputed() {
      return function (row) {
        let content = row.content;
        if(content) {
          if(content.type == 1) {
            return "icon-dingdan1";
          } else if(content.type == 2) {
            return "icon-yundan";
          } else if(content.type == 3) {
            return "icon-daka";
          } else {
            return "icon-shuaxin";
          }
        }
      }
    },
    systemName() {
      return (row) => {
        let content = row.content;
        if(content) {
          if(content.type == 1) return `${this.$isOrderTitle()}：${row.subject}`
          if(content.type == 2) return `${this.$t("home.waybill")}：${row.subject}`
          if(content.type == 3) return `${this.$t("home.clock in")}：${row.subject}`
          if(content.type == 4) return this.$tc('order.Order node change', this.$isOrderTitle())
          else content;
        }
      }
    },
    behaviorComputed() {
      return (row) => {
        let content = row.content;
        if(content) {
          if(content.type == 1) {
            if(content.status == 1) {
              return this.$t("order.Created");
            } else {
              return this.$t("order.Changed");
            }
          } else if(content.type == 2) {
            if(content.status == 1) {
              return this.$t("order.Created");
            } else {
              return this.$t("order.Changed");
            }
          } else if(content.type == 3) {
            return this.$t("order.Clocked");
          } else  {
            return this.$t("order.Changed");
          }
        }
      }
    },
    titleTypeComputed() {
      return (row) => {
        let content = row.content;
        if(content) {
          if(content.type == 1) {
            return this.$tc("order.OrderInfo", this.$isOrderTitle());
          } else if(content.type == 2) {
            return this.$t("order.shipInfo");
          } else if(content.type == 3) {
            return this.$tc("order.orderHistory", this.$isOrderTitle());
          } else {
            return this.$tc("order.Order node", this.$isOrderTitle());
          }
        }
      }
    },
    fieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue1, 'value', 'key')
        } else {
          return row.fieldValue1;
        }
      }
    },
    fieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Country' || row.fieldName === '国家') {
          return this.$isFindData(this.country, row.fieldValue, 'value', 'key')
        } else {
          return row.fieldValue;
        }
      }
    },
    shipFieldValueComputed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue;
        }
      }
    },
    shipFieldValue1Computed() {
      return (row) => {
        if(row.fieldName === 'Shipping Type' || row.fieldName === '运输方式') {
          if(row.fieldValue1 == '0') {
            return this.$t('createShipping.Sea');
          }
          return this.$t('createShipping.Air');
        } else {
          return row.fieldValue1;
        }
      }
    }
  },
  mounted() {
    this.onGetList(this.data);
  },
  props: {
    form: {
      type: Object,
      default: {}
    }
  },
  watch: {
    form: {
      handler(val) {
        this.data = {
          ...this.data,
          currentPage: 1,
          username: val.otherUsername,
          startTime: val.ListTime?.length ? dayjs(val.ListTime[0]).format("YYYY-MM-DD HH:mm:ss") : "",
          endTime: val.ListTime?.length ? dayjs(val.ListTime[1]).format("YYYY-MM-DD HH:mm:ss") : "",
          piOrCi: val.orderCI,
          excludeRead: val.excludeRead || undefined
        }
        this.totalPage = 0;
        this.initialization();
      },
      deep: true
    },
    eventUpdate() {
      this.onGetList(true);
    },
    eventAllRead() {
      this.onGetList(true);
    }
  },
  methods: {
    initialization() {
      this.eventList.splice(0, this.eventList.length);
      if (this.data.currentPage == 1) {
        this.onGetList(true);
      } else {
        this.data.currentPage = 1;
      }
    },
    load() {
      if (this.data.currentPage < this.totalPage) {
        this.data.currentPage += 1;
        this.onGetList();
      }
    },
    onGetList(clear) {
      this.loading = true;
      getEventList(this.data).then((data) => {
        if (clear) this.eventList = [];
        this.eventList = this.eventList.concat(data.list.map((res) => ({
          ...res,
          isSystem: res.contentJson,
          updateTime: dayjs(res.createTime).format("MM/DD HH:mm"),
          detailOpen: false,
          content: res.keyEvent ? res.contentJson : res.content
        })));
        this.totalPage = data.pages;
      }).finally(() => (this.loading = false));
    },
    jump(item) {
      eventRead(item.eventId).then((data) => {
        if(!data) return;
        item.read = true;
        this.$store.dispatch("user/setReadStatus", "READEVENT");
        this.reload(["OrderDetails"]);
        this.$router.push({
          path: "/order/orderDetails",
          query: {
            orderId: item.orderId,
            eventId: item.eventId
          },
        });
      });
    }
  },
};
</script>
<style lang="less" scoped>
.eventBox {
  height: 100%;
  .chat_top {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E4EA;
    padding: 0 20px;
    font-size: 14px;
    line-height: 20px;
    color: #122545;
    font-weight: 500;
    span {
      color: #076F49;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .eventList {
    padding: 20px 0;
    width: 100%;
    height: calc(100% - 60px);
    overflow: overlay;
    .itemBox {
      display: flex;
      justify-content: space-between;
      padding: 16px 20px 20px 16px;
      cursor: pointer;
      margin-bottom: 24px;
      &:hover {
        background: #F7F9FC;
        .conversationBubble {
          background: #ffffff;
        }
        /deep/ .commentChidlrenBox {
          article {
            background: #ffffff !important;
          }
        }
      }
      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #076F49;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 18px;
      }
      .content {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        margin-left: 10px;
        .content_user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          margin-top: 5px;
          .active {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #DC3545;
          }
          div {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #122545;
            span {
              color: #076F49;
            }
          }
          p {
            font-size: 14px;
            line-height: 20px;
            color: #122545;
            font-weight: 500;
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          .commentChidlrenBox {
            flex: 1;
          }
          time {
            display: block;
            width: 100px;
            text-align: right;
            font-size: 12px;
            line-height: 17px;
            color: #757D8A;
          }
        }
      }
    }
  }
}
.conversationBubble {
  background: #F7F9FC;
  border-radius: 4px;
  padding: 12px 12px;
  width: 50%;
  .headerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      .content {
        font-size: 12px;
        display: flex;
        flex-wrap: wrap;
        margin-left: 8px;
        gap: 4px;
        .name {
          color: #122545;
          font-weight: 500;
        }
        .behavior {
          color: #637381;
        }
        .title {
          color: #076F49;
          font-weight: 500;
        }
      }
    }
    .detail {
      font-size: 12px;
      color: #637381;
      cursor: pointer;
    }
  }
  .clockInContent {
    margin-left: 3px;
    margin-top: 10px;
    margin-bottom: 4px;
    .text {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      span {
        color: #076F49;
      }
    }
  }
  .changeContent {
    margin-top: 10px;
    margin-left: 3px;
    margin-bottom: 4px;
    .item {
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
  }
  .shipContent {
    margin-left: 3px;
    margin-top: 10px;
    .itemTwo {
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      color: #637381;
      line-height: 16px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        color: #076F49;
        font-weight: 600;
      }
    }
    .shipBox {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33%;
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        span {
          &:first-child {
            font-size: 12px;
            line-height: 16px;
            color: #637381;
            .tag {
              background: #ffffff;
              border-radius: 4px;
              padding: 0 4px;
              font-size: 12px;
              line-height: 0;
              color: #076F49;
            }
          }
          &:last-child {
            font-size: 14px;
            line-height: 16px;
            margin-top: 2px;
            color: #122545;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
