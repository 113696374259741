<template>
  <div class="pingListBox">
    <div class="left" @click.prevent="isOpenGroupChatSetting = false">
      <div class="search">
        <el-autocomplete
          v-model="data.keyword"
          :fetch-suggestions="querySearchAsync"
          @select="handleSelect"
          :placeholder="$t('order.Search')"
          clearable
          style="width: 100%;"
          suffix-icon="el-icon-search"
          size="small"
          @change="onGetList()"
        >
          <template slot-scope="{ item }">
            <div class="customer_name">
              <div 
                class="avatarList" 
                v-if="item.user.memberList && item.user.memberList.length">
                <div class="imageBox">
                  <img 
                    v-for="(row, index) in item.user.memberList.filter((row, index) => index < 4)" 
                    :src="row.avatarFileUrl" 
                    :key="index"
                  />
                </div>
              </div>
              <el-image v-else :src="item.user && item.user.avatarFileUrl" fit="cover" />
              <div class="companyName">
                <div class="name">{{ item.user.nickName || item.user.username }}</div>
              </div>
            </div>
          </template>
        </el-autocomplete>
      </div>
      <div 
        class="pingList" 
        v-infinite-scroll="load" 
        :infinite-scroll-distance="20">
        <div 
          class="item" 
          v-for="(item, index) in pingList" 
          :key="index"
          :class="{
            active: activeId == item.user.openId,
            topActive: item.top
          }"
          @click.prevent="activeId = item.user.openId">
          <div 
            class="avatarList" 
            v-if="item.user.memberList && item.user.memberList.length">
            <div class="imageBox">
              <img 
                v-for="(row, index) in item.user.memberList.filter((row, index) => index < 4)" 
                :src="row && row.avatarFileUrl" 
                :key="index"
              />
            </div>
            <div class="badge" v-if="item.unReadNum">{{ item.unReadNum }}</div>
          </div>
          <member
            v-else
            :info="item.user"
            :size="48"
            :placement="'left'"
            :isPing="false"
            :isBadge="item.unReadNum"
          />
          <div class="content">
            <div class="top">
              <div class="name">
                <p>{{ item.user.nickName }}</p>
              </div>
              <!-- <div class="count" v-if="item.unReadNum">{{ item.unReadNum > 99 ? '99+' : item.unReadNum }}</div> -->
              <el-popover
                ref="popover_message"
                class="el-pop"
                placement="right-start"
                trigger="click"
                :offset="-10"
                @hide="item.check = false"
                popper-class="ec-popper"
              >
                <div class="popperMenuItem" @click="setTop(item.user.openId, index)">
                  <i class="el-icon-download rotate180" />
                  <span>{{ item.top ? $t('home.Cancel Topping') : $t('home.Chat Top') }}</span>
                </div>
                <i
                  class="iconfont icon-Ellipsis downEllipsis"
                  :class="item.check ? 'active' : ''"
                  slot="reference"
                  @click.stop="activePopover(item)"
                />
              </el-popover>
            </div>
            <div class="bottom">
              <div class="message">
                <p>{{ item.content }}</p>
              </div>
              <div class="time">{{ isToday(item.createTime) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="chat_top" @click.prevent="isOpenGroupChatSetting = false">
        <div class="user" v-if="otherParty">
          <div 
            class="avatarList" 
            v-if="otherParty.memberList && otherParty.memberList.length">
            <img 
              v-for="(row, index) in otherParty.memberList.filter((row, index) => index < 4)" 
              :src="row && row.avatarFileUrl" 
              :key="index"
            />
          </div>
          <member v-else :info="otherParty" :size="40" :isPing="false" />
          <div class="user_name">
            <span>
              {{ otherParty.nickName }}
            </span>
            <div>
              {{ otherParty.position }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="live-msg-container"
        ref="reference"
        @click.prevent="isOpenGroupChatSetting = false"
        @scroll="scroll($event)"
        v-if="historyMessages.length">
        <div 
          v-for="(item, index) in $uniqueArray(historyMessages, 'chatId')" 
          :key="index" 
          class="chatItem"
          :id="'chat'+item.chatId">
          <div v-if="onGetYears(item, index)" class="time">
            {{ onGetYears(item, index) }}
          </div>
          <div class="groupMessageTypeBox" v-if="messageGroupType.includes(item.messageType) || item.withdraw == 1">{{ item.content }}</div>
          <div
            class="live-msg-item"
            v-else
            :class="item.current ? 'flexRight' : ''">
            <member
              :info="item.createUser"
              :size="40"
              :isPing="false"
            >
            </member>
            <div class="lmi-wrapper">
              <conversation-bubble 
                :item="item" 
                :otherParty="otherParty"
                @contextmenu.native="chatPopoverAction($event, item)"
              ></conversation-bubble>
              <div 
                class="contextMenuPopover" 
                v-if="item.current && isContentMenu && contentMenuActiveId == item.chatId"
                :style="contextMenuStyle">
                <div class="item" @click="withdrawMessage">
                  <i class="iconfont icon-chehui"></i>
                  {{ $t('withdraw') }}
                </div>
              </div>
              <time>{{ dayjsFormat(item.createTime) }}</time>
            </div>
          </div>
        </div>
      </div>
      <div class="live-msg-container" @click.prevent="isOpenGroupChatSetting = false" v-else></div>
      <div 
        v-if="otherParty && otherParty.groupId && isOpenGroupChatSetting"
        class="groupChatSetting" 
        :class="{
          isTransform: isOpenGroupChatSetting
        }">
        <el-input 
          v-model="searchGroupChatMemeber" 
          :placeholder="$t('order.Search')" 
          size="small" 
          prefix-icon="el-icon-search"
        />
        <div class="groupChatSettingBox">
          <div class="memberList">
            <div 
              class="item" 
              v-for="(item, index) in otherParty.memberList.filter(item => item.nickName.toLowerCase().indexOf(this.searchGroupChatMemeber.toLowerCase()) != -1)" 
              :key="index">
              <member 
                :info="item" 
                :size="40" 
                :isPing="false" 
              />
              <div class="name line1">{{ item.nickName }}</div>
              <i 
                class="el-icon-error" 
                v-if="otherParty.admin.openId == businessUserId && item.openId != businessUserId"
                @click="deleteGroupChatMember(item)"
              ></i>
            </div>
            <div class="item" @click="openCreateGroup(otherParty)">
              <div class="addIcon">
                <i class="el-icon-plus"></i>
              </div>
              <div class="name">{{ $t('home.CIAdd') }}</div>
            </div>
          </div>
          <div class="groupChatNameBox">
            <div class="label">{{ $t('GroupChat.Group chat name') }}</div>
            <div class="groupNameInput">
              <p class="name" v-if="!isGroupNameEdit" @click="setGroupNameEdit">{{ otherParty.groupName }}</p>
              <el-input 
                v-else 
                ref="groupNameDom" 
                v-model="otherParty.groupName" 
                :maxlength="30" 
                size="mini" 
                @blur="groupNameBlur"
              />
              <i class="iconfont icon-bianji3" v-if="!isGroupNameEdit"></i>
            </div>
          </div>
          <div class="specifyChat">
            <div class="label">{{ $t('GroupChat.Specify Chat') }}</div>
            <el-switch
              v-model="chatTop"
              size="small"
              active-color="#48AC42"
              inactive-color="#d8d8d8"
              @change="setTop(otherParty.groupId)">
            </el-switch>
          </div>
        </div>
        <div class="settingFooter">
          <p @click="exitGroupChat">{{ $t('GroupChat.Exit group chat') }}</p>
          <p @click="dissolveGroupChat" v-if="otherParty.admin.openId == businessUserId">{{ $t('GroupChat.Dissolve group chat') }}</p>
        </div>
      </div>
      <div class="liveFooter" @click="isOpenGroupChatSetting = false">
        <div class="liveAction">
          <div class="actionLeft">
            <toolTipIcon 
              icon="el-icon-picture-outline" 
              :tooltipText="$t('image')"
              @click="uploadImage"
            />
            <toolTipIcon 
              icon="el-icon-folder" 
              :tooltipText="$t('home.Files')"
              @click="uploadFile"
            />
            <toolTipIcon 
              icon="el-icon-chat-dot-round" 
              :tooltipText="$t('Chat records')"
              @click="openMessageHistory"
            />
            <!-- <el-divider -->
              <!-- v-if="companyCurrentType == 0 && purchaseStatus == 1 && (otherParty && otherParty.company && otherParty.company.purchaseStatus == 1) && !(otherParty && otherParty.groupId) && (businessUserId != (otherParty && otherParty.openId)) && (otherParty && otherParty.company.companyType != 2) && (otherParty && otherParty.company.companyType != 0)" -->
              <!-- direction="vertical" -->
            <!-- /> -->
            <!-- <toolTipIcon  -->
              <!-- v-if="companyCurrentType == 0 && purchaseStatus == 1 && (otherParty && otherParty.company && otherParty.company.purchaseStatus == 1) && !(otherParty && otherParty.groupId) && (businessUserId != (otherParty && otherParty.openId)) && (otherParty && otherParty.company.companyType != 2) && (otherParty && otherParty.company.companyType != 0)" -->
              <!-- icon="iconfont icon-caigoudanguanli"  -->
              <!-- :tooltipText="$t('Purchase.title')" -->
              <!-- @click="openPurchaseDialog" -->
            <!-- /> -->
            <input 
              ref="uploadInputImage" 
              type="file" 
              accept="image/*" 
              @change="uploadImageInput"
            />
            <input ref="uploadInputFile" type="file" accept="application/pdf, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  @change="uploadFileInput" />
          </div>
          <div class="actionRight" v-if="otherParty && otherParty.memberList">
            <toolTipIcon 
              icon="iconfont icon-shezhi" 
              :tooltipText="$t('GroupChat.Group chat settings')"
              @click.stop="openGroupChatSetting"
            />
          </div>
        </div>
        <el-input 
          ref="sendInput"
          type="textarea" 
          resize="none"
          v-model="text"
          :disabled="isLoading" 
          @keyup.enter.native="sedMessage()" 
        />
        <div class="messageBtns">
          <!-- <div class="soundRecords" @click="openSoundRecords"> -->
            <!-- <i class="el-icon-microphone"></i> -->
          <!-- </div> -->
          <div class="sendButton" :class="!text ? 'disabled' : ''" @click="sedMessage()">{{ $t('people.send') }}</div>
        </div>
      </div>
    </div>
    <messageHistory ref="messageHistory" :otherParty="otherParty" @locateMessage="locateMessage" />
    <createGroupChatDialog 
      ref="createGroupChatDialog" 
      @update="updateGroupChat" 
      @sendGroupChatMsg="sendGroupChatMsg"
    />
    <manageTranfer ref="manageTranfer" isGroup @exit="exitGroupEvent" />
    <audioDialog ref="audioDialog" @sendPublicMessage="sendPublicMessage" />
  </div>
</template>
<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { uniqBy } from "lodash";
import {
  ChatTop,
  messageSessionList,
  queryMessageRecord,
  sendChatData,
  updateGroupChatName,
  deleteGroupChatMember,
  exitGroupChatApi,
  dissolveGroupChatApi,
  withdrawMessage
} from '@/api/news';
import { uploadFileUrl } from "@/api/files";
import { purchaseWithdrawApi } from "@/api/order";

import messageHistory from "@/components/messageHistory";
import createGroupChatDialog from '@/components/messageDialog/createGroupChatDialog';
import manageTranfer from '@/components/MemberList/manageTranfer.vue';
import audioDialog from "@/components/audioDialog/index.vue";

export default {
  name: 'pingList',
  components: {
    messageHistory,
    createGroupChatDialog,
    manageTranfer,
    audioDialog
  },
  provide() {
    return {
      parent: this,
      isOneClickTranslate: () => {
        return false
      }
    };
  },
  data() {
    return {
      localeConfig: false,
      isGroupNameEdit: false,
      bottomShow: true,
      isOpenGroupChatSetting: false,
      data: {
        keyword: "",
        currentPage: 1,
        pageSize: 10000
      },
      totalPage: 0,
      searchGroupChatMemeber: "",
      pingList: [],
      loading_ping: true,
      chatTop: false,
      //聊天窗参数
      loading_chat: true,
      activeId: '',
      historyMessages: [],
      messageGroupType: [5,6,7,8,9],
      text: '',
      isLoading: false,
      isContentMenu: false,
      contentMenuActiveId: null,
      contentMenuActiveType: "",
      contentMenuActiveContent: "",
      contextMenuStyle: {
        left: "",
        top: ""
      },
      groupNameCopy: "",
      chatGroupId: ""
    };
  },
  computed: {
    ...mapGetters([
      'userInfo', 
      'businessUserId',
      'chatMessage',
      "chatRead",
      "groupChatUpdate",
      "messgaeWithdrawUpdate",
      "groupChatDeleteUpdate",
      "groupMemberDeleteUpdate",
      "companyCurrentType",
      "purchaseStatus"
    ]),
    otherParty() {
      let item = this.pingList.find((v) => {
        return v?.user?.openId == this.activeId;
      });
      this.chatTop = item?.top;
      this.groupNameCopy = item?.user && item.user?.nickName;
      this.chatGroupId = item?.user && item.user?.openId;
      return item?.user || null;
    }
  },
  activated() {
    if (this.activeId) {
      this.setTopChatRoom();
    }
  },
  mounted() {
    this.onGetList();
  },
  props: {
    form: {
      type: Object,
      default: {}
    }
  },
  watch: {
    form: {
      handler() {
        this.data = {
          ...this.data,
          ...this.form,
          currentPage: 1,
          keyword: this.form.otherUsername,
        }
        this.totalPage = 0;
        this.onGetList();
      },
      deep: true
    },
    'data.currentPage'() {
      this.onGetList();
    },
    activeId(val) {
      if(!val) return;
      this.text = "";
      this.historyMessages = [];
      this.onGetChatList();
    },
    groupChatUpdate() {
      this.isOpenGroupChatSetting = false;
      this.historyMessages = [];
      this.$nextTick(() => {
        this.updateGroupChat();
      })
    },
    groupMemberDeleteUpdate() {
      this.isOpenGroupChatSetting = false;
      this.historyMessages = [];
      this.$nextTick(() => {
        this.updateGroupChat();
      })
    },
    groupChatDeleteUpdate() {
      this.isOpenGroupChatSetting = false;
      this.historyMessages = [];
      this.$nextTick(() => {
        this.updateGroupChat();
      })
    },
    messgaeWithdrawUpdate: {
      handler(val) {
        this.onGetList(true);
        this.historyMessages.forEach((item, index) => {
          if(item.chatId == val.content) {
            if(item.createUser) {
              item.withdraw = 1;
              if(item.createUser?.openId == this.businessUserId) {
                item.content = this.$t("You withdrew a message");
              } else {
                item.content = item.createUser.nickName+' '+this.$t("withdrew a message");
              }
            }
          }
        });
      },
      deep: true
    },
    chatMessage(val) {
      this.onGetList(true);
      if(!val.groupId ? (val.user.openId == this.otherParty.openId || val.user.openId == this.businessUserId) : (val.groupId == this.otherParty.openId)) {
        if(!val.current) {
          window?.ws.sendMsg({
            chatId: val.chatId,
            userId: this.activeId,
            toUserId: this.businessUserId,
            connectionType: 10004,
            chatType: 1,
          });
        }
        let arr = JSON.parse(JSON.stringify(this.historyMessages));
        arr.push({
          ...val,
          createUser: val.user
        })
        this.historyMessages = arr;
        this.setTopChatRoom();
      }
    },
    chatRead() {
      if(this.$route.name === 'MessageList') {
        this.onGetList(true);
      }
    }
  },
  beforeDestory() {
    document.removeEventListener("paste", this.pasteEvent);
  },
  deactivated() {
    document.removeEventListener("paste", this.pasteEvent);
  },
  methods: {
    openGroupChatSetting() {
      this.isOpenGroupChatSetting = true;
    },
    updateGroupChat() {
      this.onGetList(true);
    },
    openPurchaseDialog() {
      this.$myEvent.emit("openPurchase", {
        mode: 1,
        otherParty: this.otherParty
      });
    },
    openSoundRecords() {
      this.$refs.audioDialog.open();
    },
    sendPublicMessage(obj) {
      this.historyMessages.push(obj);
      this.setTopChatRoom();
      sendChatData({
        toUserId: this.otherParty.openId,
        content: obj.content,
        fileId: obj.chatFileId,
        messageType: obj.messageType,
        chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
      }).then((data) => {
        let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
        pingFilter.content = obj.content;
        let msgFilter = this.historyMessages.find(item => item.chatId == obj.chatId);
        msgFilter.chatId = data.chatId;
        msgFilter.loading = false;
      });
    },
    withdrawMessage() {
      if(this.contentMenuActiveType == 10) {
        this.$confirm(
          this.$t("Purchase.The purchase order will be saved in the purchase order list"), 
          this.$t("Purchase.Are you sure to withdraw the purchase order?"), 
          {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }
        ).then(() => {
          withdrawMessage(this.contentMenuActiveId).then(() => {
            purchaseWithdrawApi(this.contentMenuActiveContent);
          });
        }).catch(() => {});
        return;
      }
      withdrawMessage(this.contentMenuActiveId);
    },
    chatPopoverAction(e, row) {
      e.preventDefault();
      var now = new Date(this.$getTimeZone(this.userInfo.timeZone)).getTime();
      if(now > (new Date(row.createTime).getTime() + 5 * 60 * 1000)) return;
      this.isContentMenu = true;
      this.contentMenuActiveId = row.chatId;
      this.contentMenuActiveType = row.messageType;
      this.contentMenuActiveContent = row.content;
      this.contextMenuStyle = {
        left: e.clientX+'px',
        top: e.clientY+'px'
      }
    },
    sendGroupChatMsg() {
      sendChatData({
        toUserId: data,
        content: this.$t('GroupChat.hello'),
        messageType: 5,
        chatType: 1
      }).then((data) => {
        let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
        pingFilter.content = textContent;
        let msgFilter = this.historyMessages.find(item => item.chatId == time);
        msgFilter.chatId = data.chatId;
        msgFilter.loading = false;
      });
    },
    openCreateGroup(row = null) {
      this.$refs.createGroupChatDialog.open(row);
    },
    exitGroupEvent(openId = null) {
      if(openId) {
        sendChatData({
          toUserId: this.otherParty.groupId,
          content: " ",
          messageType: 9,
          chatType: 1
        }).then(() => {
          exitGroupChatApi(this.otherParty.groupId, openId).then(() => {
            this.$refs.manageTranfer.close();
           })
        });
        return;
      }
      this.$confirm(
        this.$t('GroupChat.After exiting, the group chat content will be cleared'), 
        this.$t("GroupChat.Are you sure to exit the group chat"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        sendChatData({
          toUserId: this.otherParty.groupId,
          content: " ",
          messageType: 9,
          chatType: 1
        }).then(() => {
          exitGroupChatApi(this.otherParty.groupId, openId);
        });
      }).catch(() => {});
    },
    exitGroupChat() {
      if(this.otherParty.admin.openId == this.businessUserId) {
        if(this.otherParty.memberList.length > 1) {
          console.log(this.$refs.manageTranfer);
          this.$refs.manageTranfer.open(null, this.otherParty.memberList);
        } else {
          this.exitGroupEvent()
        }
        return;
      }
      this.exitGroupEvent();
    },
    dissolveGroupChat() {
      this.$confirm(
        this.$t('GroupChat.After dissolve, the group chat content will be cleared'), 
        this.$t("GroupChat.Are you sure to dissolve the group chat"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        dissolveGroupChatApi(this.otherParty.groupId).then(() => {
          this.activeId = null;
          this.pingList = [];
        })
      }).catch(() => {});
    },
    setGroupNameEdit() {
      this.isGroupNameEdit = true;
      this.$nextTick(() => {
        this.$refs.groupNameDom.focus();
      });
    },
    deleteGroupChatMember(row) {
      this.$confirm(
        this.$t('GroupChat.Do you want to delete this member'), 
        this.$t("Tips"),
        {
          confirmButtonText: this.$t('Confirm'),
          cancelButtonText: this.$t('Cancel'),
          type: 'warning'
        }
      ).then(() => {
        deleteGroupChatMember({
          groupId: this.otherParty.groupId,
          openIds: row.openId
        }).then(() => {
          this.$message.success(this.$t('event.Delete successfully'));
          sendChatData({
            toUserId: this.otherParty.groupId,
            content: row.nickName,
            messageType: 8,
            chatType: 1
          });
        })
      }).catch(() => {});
    },
    groupNameBlur() {
      this.isGroupNameEdit = false;
      if(this.otherParty.groupName === this.groupNameCopy) return;
      updateGroupChatName({
        groupId: this.otherParty.groupId,
        groupName: this.otherParty.groupName
      }).then(() => {
        sendChatData({
          toUserId: this.otherParty.groupId,
          content: this.otherParty.groupName,
          messageType: 6,
          chatType: 1
        });
      })
    },
    uploadImage() {
      this.$refs.uploadInputImage.click();
    },
    uploadFile() {
      this.$refs.uploadInputFile.click();
    },
    uploadFileInput(event) {
      let files = event.target.files[0];
      if(!this.beforeFileUpload(files)) return;
      const data_up = new FormData();
      data_up.append("files", files);
      data_up.append("configCode", "chatFile");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        let time = new Date().getTime();
        let obj = {
          chatId: time,
          chatFileId: response[0].fileId,
          chatFileUrl: response[0].savePath,
          loading: true,
          content: this.$t('Files.shared the file'),
          messageType: 4,
          current: true,
          read: 0,
          createTime: this.$getTimeZone(this.userInfo.timeZone),
          createUser: this.userInfo
        }
        this.historyMessages.push(obj);
        this.setTopChatRoom();
        sendChatData({
          toUserId: this.otherParty.openId,
          content: this.$t('Files.shared the file'),
          fileId: response[0].fileId,
          messageType: 4,
          chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
        }).then((data) => {
          let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
          pingFilter.content = this.$t('Files.shared the file');
          let msgFilter = this.historyMessages.find(item => item.chatId == time);
          msgFilter.chatId = data.chatId;
          msgFilter.loading = false;
        });
      })
    },
    uploadImageInput(event) {
      let files = event.target.files[0]
      if (!this.beforeAvatarUpload(files)) return;
      const data_up = new FormData();
      data_up.append("files", files);
      data_up.append("configCode", "file_chat_content_img");
      uploadFileUrl(data_up).then((response) => {
        if (!response) return;
        let imgDom = (<img style="width: 100%" src={response[0].savePath} />);
        let imgDomString = `<img src="${response[0].savePath}" />`;
        this.$confirm(imgDom, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          let time = new Date().getTime();
          let obj = {
            chatId: time,
            chatFileId: null,
            chatFileUrl: null,
            loading: true,
            content: imgDomString,
            messageType: 3,
            current: true,
            read: 0,
            createTime: this.$getTimeZone(this.userInfo.timeZone),
            createUser: this.userInfo
          }
          this.historyMessages.push(obj);
          this.setTopChatRoom();
          sendChatData({
            toUserId: this.otherParty.openId,
            content: imgDomString,
            messageType: 3,
            chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
          }).then((data) => {
            let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
            pingFilter.content = `[${this.$t('image')}]`;
            let msgFilter = this.historyMessages.find(item => item.chatId == time);
            msgFilter.chatId = data.chatId;
            msgFilter.loading = false;
          });
        }).catch(() => {});
      })
      event.target.value = "";
    },
    beforeFileUpload({ size, type }) {
      const isLt2M = size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isLt2M;
    },
    beforeAvatarUpload({ size, type }) {
      const isJPG =
          type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/jpg' ||
          type === 'image/gif';
      const isLt2M = size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(window.vm.$t('customerInfo.Picture must be JPG or PNG or GIF!'));
      }
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isJPG && isLt2M;
    },
    pasteEvent(event) {
      let text = event.clipboardData || window.clipboardData;
      if(text.files && text.files.length) {
        if (!text.files[0].type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) return;
        const data_up = new FormData();
        data_up.append("files", text.files[0]);
        data_up.append("configCode", "file_chat_content_img");
        uploadFileUrl(data_up).then((response) => {
          if (!response) return;
          let imgDom = (<img style="width: 100%" src={response[0].savePath} />);
          let imgDomString = `<img src="${response[0].savePath}" />`;
          this.$confirm(imgDom, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            let time = new Date().getTime();
            let obj = {
              chatId: time,
              chatFileId: null,
              chatFileUrl: null,
              loading: true,
              content: imgDomString,
              messageType: 3,
              current: true,
              read: 0,
              createTime: this.$getTimeZone(this.userInfo.timeZone),
              createUser: this.userInfo
            }
            this.historyMessages.push(obj);
            this.setTopChatRoom();
            sendChatData({
              toUserId: this.otherParty.openId,
              content: imgDomString,
              messageType: 3,
              chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
            }).then((data) => {
              let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
              pingFilter.content = `[${this.$t('image')}]`;
              let msgFilter = this.historyMessages.find(item => item.chatId == time);
              msgFilter.chatId = data.chatId;
              msgFilter.loading = false;
            });
          }).catch(() => {});
        }).catch((error) => {});
      }
    },
    scroll(e) {
      if(this.localeConfig) return;
      const body = e.target;
      // 获取距离顶部的距离
      const scrollTop = body?.scrollTop;
      // 获取可视区的高度
      const windowHeight = body.clientHeight;
      if (windowHeight - scrollTop >= windowHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        this.bottomShow = false;
        this.onGetChatList(false);
      } else {
        this.bottomShow = true;
      }
    },
    openMessageHistory() {
      this.$refs.messageHistory.open();
    },
    setTopChatRoom() {
      setTimeout(() => {
        if(this.$refs.reference) {
          this.$refs.reference.scrollTo({
            top: this.$refs.reference?.scrollHeight,
            behavior: 'instant'
          })
        }
      }, 300);
    },
    querySearchAsync(queryString, cb) {
      if(!queryString) {
        cb([]);
        return;
      }
      var data_name = {
        keyword: queryString,
        currentPage: 1,
        pageSize: 10000
      };
      messageSessionList(data_name).then((data) => {
        cb(data.list.map(item => ({
          ...item,
          user: item.user || {
            ...item.group,
            openId: item.group.groupId,
            nickName: item.group.groupName
          }
        })));
      }).catch((error) => {
        this.$message.error('querySearchAsync error');
      });
    },
    handleSelect(item) {
      this.data.keyword = item?.user?.nickName;
      this.onGetChatList();
    },
    //聊天置顶显示按钮
    activePopover(row) {
      row.check = !row.check;
      this.$nextTick(() => {
        this.pingList.forEach((item, index) => {
          if(item.user.openId != row.user.openId) {
            item.check = false;
            this.$refs.popover_message[index].showPopper = false;
          }
        });
      });
    },
    setTop(id, index = null) {
      ChatTop(id).then(() => {
        this.onGetList();
        if(index == null) return;
        this.$refs.popover_message[index].showPopper = false;
      });
    },
    //分页
    load() {
      if (this.data.currentPage < this.totalPage) {
        // this.data.currentPage += 1;
      }
    },
    //获取用户聊天列表
    onGetList(isUpdateMessage = false) {
      this.loading_ping = true;
      messageSessionList(this.data)
        .then((data) => {
          if(isUpdateMessage) this.pingList = [];
          this.totalPage = data.total;
          this.pingList = data.list.map(item => ({
            ...item,
            user: item.user || {
              ...item.group,
              openId: item.group.groupId,
              nickName: item.group.groupName,
            },
            check: false,
            content: item.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, ""),
          }));
          if(this.data.currentPage == 1 && this.pingList.length) {
            if(this.pingList.map(item => item.user?.openId).includes(this.activeId)) {
              this.activeId = this.activeId;
            } else {
              this.activeId = this.pingList[0]?.user.openId;
            }
          } else {
            if(this.pingList.length) {
              this.activeId = this.pingList[0].user?.openId;
            }
          }
        })
        .finally(() => (this.loading_ping = false));
    },
    locateMessage(id) {
      let chatDom = document.querySelector(`#chat${id}`);
      if(chatDom) {
        chatDom.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      } else {
        this.forEventApi(id);
      }
    },
    forEventApi(id) {
      this.localeConfig = true;
      this.isLoading = true;
      let localeId = this.historyMessages.length && this.historyMessages[0].chatId;
      queryMessageRecord({
        size: 20,
        next: false,
        locateChatId: localeId,
        chatUser: this.activeId
      }).then(data => {
        if(!data.length) return;
        this.historyMessages.unshift(
          ...data.reverse()
        );
        this.historyMessages.forEach((item, index) => {
          if(item.withdraw == 1) {
            if(item.createUser.openId == this.businessUserId) {
              item.content = this.$t("You withdrew a message");
            } else {
              item.content = item.createUser.nickName+' '+this.$t("withdrew a message");
            }
          }
        });
        if(data.map(item => item.chatId).includes(id)) {
          this.$nextTick(() => {
            let chatDom = document.querySelector(`#chat${id}`);
            chatDom.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          })
          this.localeConfig = false;
          return;
        }
        this.forEventApi(id);
      }).finally(() => this.isLoading = false);
    },
    //获取消息列表
    onGetChatList(isUpdate = true) {
      this.isLoading = true;
      this.loading_chat = true;
      let localeId = this.historyMessages.length && this.historyMessages[0].chatId;
      queryMessageRecord({ 
        size: 20,
        next: this.bottomShow,
        locateChatId: localeId,
        chatUser: this.activeId
      })
        .then((data) => {
          this.historyMessages.unshift(
            ...data.reverse()
          );
          this.historyMessages.forEach((item, index) => {
            if(item.withdraw == 1) {
              if(item.createUser.openId == this.businessUserId) {
                item.content = this.$t("You withdrew a message");
              } else {
                item.content = item.createUser.nickName+' '+this.$t("withdrew a message");
              }
            }
          });
          this.$store.dispatch("user/setChatRead", new Date().getTime());
          document.addEventListener('paste', this.pasteEvent);
          if(isUpdate) {
            this.setTopChatRoom();
          } else {
            this.$nextTick(() => {
              if(document.getElementById(localeId)) {
                document.getElementById(localeId).scrollIntoView({
                  block: "center"
                });
              }
            })
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.loading_chat = false;
        });
    },
    dayjsFormat(sendDateStr) {
      return dayjs(sendDateStr).format('HH:mm:ss');
    },
    //消息发送
    sedMessage() {
      if (!this.text.trim().length) {
        this.text = "";
        return;
      }
      let textContent = this.$replaceLinks(this.text);
      let time = new Date().getTime();
      let obj = {
        chatId: time,
        chatFileId: null,
        chatFileUrl: null,
        loading: true,
        content: textContent,
        messageType: 1,
        current: true,
        read: 0,
        createTime: this.$getTimeZone(this.userInfo.timeZone),
        createUser: this.userInfo
      }
      this.historyMessages.push(obj);
      this.setTopChatRoom();
      this.text = '';
      sendChatData({
        toUserId: this.otherParty.openId,
        content: textContent,
        messageType: 1,
        chatType: this.otherParty.memberList && this.otherParty.memberList.length ? 1 : 0
      }).then((data) => {
        let pingFilter = this.pingList.find(item => item.user.openId == this.otherParty.openId);
        pingFilter.content = textContent;
        let msgFilter = this.historyMessages.find(item => item.chatId == time);
        msgFilter.chatId = data.chatId;
        msgFilter.loading = false;
      });
    },
    //查看对方个人信息
    linkTo() {
      this.$router.push({
        path: `/profile?businessUserId=${this.activeId}`,
      });
    },
    //计算顶部显示时间
    onGetYears(data, i) {
      if (i == 0) {
        return data && dayjs(data.years).format('YYYY-MM-DD');
      }
      const { years } = data; //当前时间
      let time = this.historyMessages[i - 1].years; //下一条时间
      if (
        dayjs(years).format('YYYY-MM-DD') != dayjs(time).format('YYYY-MM-DD')
      ) {
        return dayjs(years).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    isToday(time) {
      let todayDate = new Date().setHours(0,0,0,0);
      let paramsDate = new Date(time).setHours(0,0,0,0);
      if(todayDate === paramsDate) {
        return time.split(" ")[1]
      } else {
        return time.split(" ")[0]
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pingListBox {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .left {
    width: 25%;
    min-width: 250px;
    border-right: 1px solid #E0E4EA;
    .search {
      padding: 16px 20px 14px 20px;
    }
    .pingList {
      overflow: overlay;
      width: 100%;
      height: calc(100% - 62px);
      .item {
        width: 100%;
        height: 64px;
        border-bottom: 1px solid #E0E4EA;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 11px 0 19px;
        position: relative;
        .avatarList {
          width: 48px;
          height: 48px;
          // overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          position: relative;
          .imageBox {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .badge {
            position: absolute;
            top: -2px;
            left: -5px;
            border: 1px solid #ffffff;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #DC3545;
            font-size: 12px;
            line-height: normal;
            color: #ffffff;
            border-radius: 50%;
          }
        }
        .content {
          flex: 1;
          min-width: 0;
          overflow: hidden;
          margin-left: 10px;
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .top {
            width: 100%;
            margin-bottom: 4px;
            .name {
              flex: 1;
              min-width: 0;
              overflow: hidden;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: #122545;
              p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .bottom {
            font-size: 12px;
            line-height: 17px;
            .message {
              flex: 1;
              min-width: 0;
              overflow: hidden;
              color: #757D8A;
              p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .time {
              color: #A3B0C6;
            }
          }
        }
        &.topActive {
          background: #F7F9FC;
        }
        &.active {
          background: #F7F9FC;
          &::after {
            opacity: 1;
            display: block;
          }
        }
        &:hover {
          background: #F7F9FC;
        }
        &::after {
          content: "";
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background: #076F49;
          transition: all .3s;
        }
      }
    }
  }
  .right {
    flex: 1;
    position: relative;
    // background: #F7F9FC;
    .chat_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px 10px 16px;
      border-bottom: 1px solid #E0E4EA;
      .user {
        display: flex;
        align-items: center;
        .avatarList {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          background: #E3E9F1;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .user_name {
          margin-left: 10px;
          span {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #122545;
            margin-bottom: 3px;
            display: block;
            max-width: 240px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          div {
            color: #757D8A;
            font-size: 12px;
            line-height: 17px;
            max-width: 240px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .details {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #F7F9FC;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          font-size: 12px;
          color: #64748B;
        }
      }
    }
    .liveFooter {
      border-top: 1px solid #E0E4EA;
      text-align: right;
      padding: 8px 20px 12px 12px;
      .liveAction {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .actionLeft {
          display: flex;
          align-items: center;
          gap: 8px;
          /deep/ .box {
            width: 24px;
            height: 24px;
          }
          /deep/ .el-divider {
            margin: 0;
          }
          input {
            display: none;
          }
        }
        .actionRight {
          /deep/ .box {
            width: 24px;
            height: 24px;
          }
        }
      }
      /deep/ .el-textarea {
        .el-textarea__inner {
          height: 60px;
          padding: 0;
          border: none;
          background: transparent;
          font-weight: 600;
          font-family: "宋体", 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        }
      }
      .messageBtns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        .soundRecords {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: #E3E9F1;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i {
            font-size: 16px;
            color: #004C97;
          }
        }
        .sendButton {
          padding: 6px 24px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 600;
          line-height: 16.8px;
          border-radius: 4px;
          background: #076F49;
          display: inline-block;
          cursor: pointer;
          &.disabled {
            background: #A3B0C6;
            cursor: no-drop;
          }
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    .live-msg-container {
      height: calc(100% - 202px);
      padding: 0px 20px;
      display: grid;
      gap: 0 30px;
      align-content: flex-start;
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
      overflow-y: overlay;
      overflow-x: hidden;
      .chatItem {
        scroll-margin: 20px;
        .groupMessageTypeBox {
          text-align: center;
          color: #757D8A;
          font-size: 12px;
          line-height: 16.8px;
          margin-bottom: 20px;
        }
      }
      .time {
        text-align: center;
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        margin-top: 12px;
      }
      .live-msg-item {
        display: flex;
        // align-items: center;
        gap: 12px;
        margin-bottom: 10px;
        .lmi-wrapper {
          font-size: 14px;
          color: #17181c;
          margin-top: 8px;
          position: relative;
          .contextMenuPopover {
            position: fixed;
            top: 0;
            padding: 5px 0;
            z-index: 2;
            background: #ffffff;
            box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
            border-radius: 4px;
            .item {
              padding: 7px;
              color: #122545;
              font-size: 12px;
              display: flex;
              align-items: center;
              gap: 6px;
              padding-right: 20px;
              cursor: pointer;
              i {
                font-size: 12px;
                transform: scale(.8);
              }
              &:hover {
                color: #ffffff;
                background: #122545;
              }
            }
          }
          time {
            font-size: 12px;
            line-height: 20px;
            color: #A3B0C6;
          }
        }
      }
    }
    .groupChatSetting {
      position: absolute;
      right: 0;
      z-index: 1;
      top: 61px;
      width: 280px;
      height: calc(100% - 61px);
      background: #ffffff;
      border-radius: 4px 0 0 4px;
      box-shadow: 0 5px 10px #dbdbdb;
      transition: all .5s;
      transform: translateX(300px);
      padding: 16px;
      .groupChatSettingBox {
        width: 100%;
        height: calc(100% - 120px);
        overflow: overlay;
        .memberList {
          display: flex;
          flex-wrap: wrap;
          margin-top: 6px;
          .item {
            width: 49px;
            text-align: center;
            position: relative;
            margin-top: 8px;
            .addIcon {
              width: 40px;
              height: 40px;
              border: 1px dashed #E0E4EA;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #757D8A;
              font-size: 18px;
              font-weight: 800;
              cursor: pointer;
              margin: 0 auto;
            }
            .name {
              font-size: 12px;
              font-weight: 600;
              line-height: 16.8px;
              color: #637381;
              margin-top: 4px;
            }
            .el-icon-error {
              position: absolute;
              top: 0;
              right: 0px;
              color: #A3B0C6;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
        .groupChatNameBox {
          margin-top: 20px;
          .label {
            font-size: 14px;
            font-weight: 600;
            color: #122545;
            line-height: 22px;
            margin-bottom: 9px;
          }
          .groupNameInput {
            display: flex;
            align-items: center;
            gap: 6px;
            .name {
              font-size: 12px;
              height: 32px;
              line-height: 32px;
              color: #A3B0C6;
            }
            &:hover {
              i {
                opacity: 1;
              }
            }
            i {
              font-size: 12px;
              opacity: 0;
              transition: all .3s;
            }
            /deep/ .el-input {
              .el-input__inner {
                border: none;
                padding: 0;
                font-size: 12px;
                color: #A3B0C6;
                font-weight: 400;
                padding-left: 10px;
              }
            }
          }
        }
        .specifyChat {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          .label {
            font-size: 14px;
            font-weight: 600;
            color: #122545;
            line-height: 22px;
          }
        }
      }
      .settingFooter {
        text-align: center;
        padding-top: 20px;
        p {
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          line-height: 16.8px;
          &:nth-child(1) {
            color: #DC3545;
          }
          &:nth-child(2) {
            margin-top: 12px;
            color: #757D8A;
          }
        }
      }
      &.isTransform {
        transform: translateX(0);
      }
    }
  }
}
</style>
