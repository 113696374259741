<template>
  <div class="todoBox">
    <div class="left" v-loading="loading_todo">
      <div class="search">
        <div class="translateButton">
          <el-tabs 
            class="special_tabs" 
            v-model="data.assignOrNotify">
            <el-tab-pane :label="$t('home.OPERATION')" name="0" />
            <el-tab-pane :label="$t('home.NOTICE')" name="1" />
          </el-tabs>
        </div>
      </div>
      <div
        v-if="todoList.length"
        class="todoList"
        v-infinite-scroll="load"
        :infinite-scroll-distance="20"
      >
        <div
          v-for="(item, index) in todoList.filter(item => item.updatePerson)"
          :key="index"
          class="item"
          :class="activeId == item.todoItemId ? 'active' : ''"
          @click="jump(item, index)"
        >
          <div class="top">
            <member
              :info="item.updatePerson"
              :size="48"
            />
            <div class="content">
              <div class="title">{{ item.content }}</div>
              <div class="contentUser">
                <div class="create">
                  <span>
                    {{
                      item.updatePerson.nickName
                    }}
                  </span>
                  {{
                    `${item.todoStatus == '0' 
                        ? $t("InformationCenter.created")
                        : $t("InformationCenter.update")
                    }`
                  }}
                </div>
                <p>{{ dayjs(item.updateTime).format("YYYY/MM/DD HH:mm") }}</p>
              </div>
            </div>
          </div>
          <div class="bottom">
            <span
              class="status"
              :class="isTodoComputed(item).code === 3 ? 'active' : isTodoComputed(item).code === 2 ? 'end' : ''"
            >
              {{ isTodoComputed(item).name }}
            </span>
            <span class="completedStatus" v-if="item.assignMe" :class="item.finish ? 'active' : ''">
              {{ item.finish ? $t('todo.You have completed') : $t('todo.You have not completed') }}
            </span>
          </div>
        </div>
      </div>
      <el-empty
        v-else
        :image="$emptyImage"
        :image-size="90"
        :description="$t('overallSituation.noData')"
      />
    </div>
    <div class="right" v-loading="loading_todoDetails">
      <div class="chat_top">{{ todoData.ci }}</div>
      <div class="content">
        <ul class="subject">
          <li>
            <span class="text">{{ $t('xiaoxie.Title') }}</span>
            <div class="text_content">
              {{ todoData.title || todoData.content }}
            </div>
          </li>
          <li>
            <span class="text topMove">{{ $t('InformationCenter.assignedto') }}</span>
            <div class="assign_list">
              <div
                v-for="(item, index) in todoData.assignUserList"
                :key="index"
                class="assign_list_item"
              >
                <member
                  :info="item"
                  :size="28"
                  :isDone="onIsDone(item)"
                  :placement="'bottom'"
                />
                <span>{{ item.nickName }}</span>
              </div>
            </div>
          </li>
          <li>
            <span class="text topMove">{{
              $t('InformationCenter.Whendonenotify')
            }}</span>
            <ul class="assign_list">
              <li
                v-for="(item, index) in todoData.notifyUserList"
                :key="index"
                class="assign_list_item"
              >
                <member :info="item" :size="28" :placement="'bottom'" />
                <span>{{ item.name }}</span>
                <!-- {{ todoData && todoData.notifyUserList && index === todoData.notifyUserList.length - 1 ? '' : '' }} -->
              </li>
            </ul>
          </li>
          <li>
            <span class="text">{{ $t('InformationCenter.Dueon') }}</span>
            <div class="text_content">{{ todoData.ddlDate }}</div>
          </li>
          <li>
            <span class="text">{{ $t('InformationCenter.Notes') }}</span>
            <div class="text_content" style="white-space: pre-wrap;">{{ todoData.notes }}</div>
          </li>
          <li>
            <span class="text">{{ $t('InformationCenter.Status') }}</span>
            <div class="text_content">
              <span
                class="status"
                :class="isTodoComputed2(todoData).code === 3 ? 'active' : isTodoComputed2(todoData).code === 2 ? 'end' : ''"
              >
                {{ isTodoComputed2(todoData).name }}
              </span>
            </div>
          </li>
          <li>
            <span class="text">{{ $t('InformationCenter.Createby') }}</span>
            <div class="text_content">
              <span>{{
                todoData && todoData.createUser && todoData.createUser.name
              }}</span>
            </div>
          </li>
        </ul>
        <div class="record">
          <div class="record_top">{{ $t('InformationCenter.Record') }}</div>
          <ul class="item_log_list">
            <li v-for="(item, index) in itemLogList" :key="index">
              <div class="item_user">
                <member :info="item.updatePerson" :size="40" />
                <div
                  class="item_user_text"
                  :style="
                    item.afterStatus == 'Finished' ||
                    item.afterStatus == '已完成'
                      ? { color: '#13a14c' }
                      : ''
                  "
                >
                  <div class="item_user_name_box">
                    <div class="item_user_name">
                      {{ item.updatePerson.nickName }}
                    </div>
                    <div>
                      {{ 
                        item.todoStatus == '0' ? $t('todo.Create') : item.todoStatus == '1' ? $t('todo.On going') : $t('todo.Success')
                      }}
                    </div>
                  </div>
                  <span>{{ item.updateTime }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="button" v-if="todoList.length && todoData.isOperation != 1">
        <el-button
          size="small"
          type="primary"
          @click="finishTodo(todoData)"
        >
          {{
            todoData.isLight != 1 && todoData.isLight == 2
              ? $t('order.to previous step')
              : $t('InformationCenter.Finished')
          }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import {
  itemLogList,
  receiveItem,
  todoItemPage,
} from "@/api/news";
import { deleteTodoItem, getTodoItemDetail, getTodoItemRead } from "@/api/todo";
import { pickerOptions } from "@/utils/utils";

export default {
  name: "todoList",
  components: {},
  data() {
    return {
      dayjs: dayjs,
      data: {
        assignOrNotify: "0",
        currentPage: 1,
        pageSize: 15,
      },
      totalElement: 0,
      totalPage: 0,
      todoList: [],
      loading_todo: false,
      loading_todoDetails: false,
      activeId: 0,
      itemLogList: [],
      todoData: {
        ci: "",
        content: "",
        assignUserItemList: [],
        assignUserList: [],
        notifyUserList: [],
        notes: "",
        createUser: { name: "", profile: { savePath: "" } },
        itemLogList: [],
      },
    };
  },
  computed: {
    ...mapGetters(["businessUserId", "todoUpdate", "readTodo"]),
    isTodoComputed() {
      return (item) => {
        if(item.todoStatus == '0') {
          return {
            name: '未开始',
            code: 1
          }
        } 
        if(item.todoStatus == '2') {
          return {
            name: '已结束',
            code: 2
          }
        }
        return {
          name: this.$t('order.pending')+`${item.progressFinish}/${item.progressAll}`,
          code: 3
        }
      }
    },
    isTodoComputed2() {
      return (item) => {
        let userComplete = item.assignUserItemList.filter(row => row.todoStatus == '2');
        if(item.todoStatus == '0') {
          return {
            name: '未开始',
            code: 1
          }
        }
        if(item.todoStatus == '2') {
          return {
            name: '已结束',
            code: 2
          }
        }
        return {
          name: this.$t('order.pending')+`${userComplete.length}/${item.assignUserItemList.length}`,
          code: 3
        }
      }
    },
    i18n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
  },
  mounted() {
    this.onGetList(true);
  },
  props: {
    form: {
      type: Object,
      default: {}
    }
  },
  watch: {
    form: {
      handler(val) {
        this.data = {
          ...this.data,
          currentPage: 1,
          finish: val.finish,
          username: val.otherUsername,
          startTime: val.ListTime?.length ? dayjs(val.ListTime[0]).format("YYYY-MM-DD HH:mm:ss") : "",
          endTime: val.ListTime?.length ? dayjs(val.ListTime[1]).format("YYYY-MM-DD HH:mm:ss") : "",
          piOrCi: val.orderCI,
          excludeRead: val.excludeRead || undefined
        }
        this.totalPage = 0;
        this.initialization();
      },
      deep: true
    },
    'data.assignOrNotify'() {
      this.initialization();
    },
    todoUpdate() {
      this.initialization();
    }
  },
  methods: {
    deleteTodo() {
      this.$confirm(
        this.$t("xiaoxie.Delete this todo"),
        this.$t("xiaoxie.Notification"),
        {
          confirmButtonText: this.$t("xiaoxie.confirm"),
          cancelButtonText: this.$t("xiaoxie.cancel"),
          type: "warning",
        }
      ).then(() => {
        deleteTodoItem({ todoItemId: this.activeId }).then((res) => {
          const { data, msg, success } = res?.data;
          if (success) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        });
      });
    },
    onTodoStatistics() {
      this.$emit("updateCount");
    },
    //筛选初始化
    initialization() {
      this.loading_todo = false;
      this.loading_todoDetails = false;
      this.todoData = {
        ci: "",
        content: "",
        assignUserItemList: [],
        assignUserList: [],
        notifyUserList: [],
        notes: "",
        createUser: { name: "", profile: { savePath: "" } },
        itemLogList: []
      };
      this.itemLogList = [];
      this.todoList.splice(0, this.todoList.length);
      if (this.data.currentPage == 1) {
        this.onGetList(true);
      } else {
        this.data.currentPage = 1;
      }
    },
    load() {
      if (this.data.currentPage < this.totalPage) {
        this.data.currentPage += 1;
        this.onGetList();
      }
    },
    //获取todo列表
    onGetList(clear) {
      this.loading_todo = true;
      todoItemPage(this.data).then((data) => {
        if(clear) this.todoList = [];
        this.todoList = this.todoList.concat(data.list.map((res) => ({
          ...res,
          content: res.content.replaceAll(/\<[\s\S]*?\>/g, ""),
          createTime: dayjs(res.ddlDate).format("MM/DD HH:mm")
        })));
        this.totalPage = data.pages;
        if (this.data.currentPage === 1) {
          if(data.list.length) {
            this.activeId = data.list[0].todoItemId;
            this.onGetToDoData(data.list[0]);
          }
        }
      }).finally(() => (this.loading_todo = false));
    },
    jump(item) {
      getTodoItemRead(item.todoItemId).then((data) => {
        item.read = true;
        if(data) {
          this.$store.dispatch("user/setReadStatus", "READTODO");
        }
      })
      this.onGetToDoData(item);
    },
    //获取todo详情
    onGetToDoData(data) {
      this.activeId = data.todoItemId;
      this.loading_todoDetails = true;
      this.itemLogList = [];
      getTodoItemDetail(data.todoItemId).then((data) => {
        let obj = {
          ci: data.ci || data.pi,
          title: data.content,
          assignUserItemList: data.assignUsers || [],
          assignUserList: data.assignUsers,
          notifyUserList: data.notifyUsers,
          ddlDate: data.ddlDate,
          notes: data.notes,
          createUser: data.createPerson,
          todoItemId: data.todoItemId,
          todoStatus: data.todoStatus,
          isLight: data.assignUsers.filter(item => item.openId == this.businessUserId)[0] && data.assignUsers.filter(item => item.openId == this.businessUserId)[0].todoStatus,
        }
        this.todoData = { ...obj, isOperation: this.distinguish(obj) }
        if (this.todoData.todoItemId) {
          itemLogList(this.todoData.todoItemId).then((data) => {
            this.itemLogList = data;
          }).finally(() => (this.loading_todoDetails = false));
        }
      })
    },
    //判断To-do是分配给我，还是完成后通知我
    distinguish(data) {
      const { assignUserList, notifyUserList, createUser } = data;
      let assignIndex = assignUserList?.findIndex((v) => {
        return v?.openId == this.businessUserId;
      });
      let notifyIndex = notifyUserList?.findIndex((v) => {
        return v?.openId == this.businessUserId;
      });
      let index = 0;
      if (assignIndex != -1 && notifyIndex != -1) {
        index = 3;
      } else if (assignIndex != -1) {
        index = 2;
      } else if (notifyIndex != -1) {
        index = 1;
      }
      //1是提醒，2是操作，3既是提醒也是操作
      return index;
    },
    //To-do状态扭转
    finishTodo(item) {
      var ask_msg = "";
      if (item.isLight == 2) {
        ask_msg = this.$t("home.Back to UNFINISHED?");
      } else {
        ask_msg = this.$t("home.Finshed this todo?");
      }
      this.$confirm(ask_msg, this.$t("home.Todo status update"), {
        confirmButtonText: this.$t("home.Confirm"),
        cancelButtonText: this.$t("home.Cancel"),
        cancelButtonClass: "cancel-btn",
      }).then(() => {
        this.loading_todoDetails = true;
        receiveItem({ 
          todoItemId: item.todoItemId, 
          todoStatus: item.isLight == '2' ? 1 : 2
        })
          .then(() => {
            this.$message.success(this.$t("order.State torsion succeeded"));
            this.onTodoStatistics();
            this.onGetToDoData(item);
            getTodoItemRead(item.todoItemId).then((data) => {
              this.onGetList(true);
              if(data) {
                this.$store.dispatch("user/setReadStatus", "READTODO");
              }
            })
          })
          .finally(() => (this.loading_todoDetails = false));
      });
    },
    //查询那些人完成了
    onIsDone(data) {
      const { assignUserItemList } = this.todoData;
      let isTrue = false;
      if (assignUserItemList.length) {
        assignUserItemList.forEach((res) => {
          if (res.openId == data?.openId && res.todoStatus == 2) {
            isTrue = true;
          }
        });
      }
      return isTrue;
    },
  },
};
</script>
<style lang="less" scoped>
.todoBox {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .left {
    width: 25%;
    min-width: 300px;
    border-right: 1px solid #E0E4EA;
    .search {
      padding: 16px 20px 14px 20px;
      .translateButton {
        width: 100%;
        height: 32px;
        /deep/ .special_tabs {
          background: #F7F9FC;
          .el-tabs__item {
            color: #757D8A;
            &.is-active {
              color: #122545;
            }
          }
          .el-tabs__active-bar {
            background: #E0E4EA;
          }
        }
      }
    }
    .todoList {
      width: 100%;
      height: calc(100% - 62px);
      overflow: overlay;
      .item {
        border-bottom: 1px solid #E0E4EA;
        transition: all .3s;
        cursor: pointer;
        position: relative;
        padding: 16px 20px;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .content {
            flex: 1;
            min-width: 0;
            margin-left: 10px;
            .title {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #122545;
              margin-bottom: 4px;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .contentUser {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
              line-height: 17px;
              
              .create {
                color: #757D8A;
                display: flex;
                align-items: center;
                span {
                  display: inline-block;
                  max-width: 60px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              p {
                color: #A3B0C6;
              }
            }
          }
        }
        .bottom {
          margin-top: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .completedStatus {
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            color: #FF8311;
            display: flex;
            align-items: center;
            gap: 8px;
            &::before {
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              background: #FF8311;
              border-radius: 50%;
            }
            &.active {
              color: #757D8A;
              &::before {
                background: #757D8A;
              }
            }
          }
          .status {
            background: #F7F9FC;
            border-radius: 50px;
            color: #757D8A;
            font-size: 12px;
            line-height: 17px;
            font-weight: 500;
            padding: 3.5px 12px;
            transition: all .3s;
            &.active {
              color: #076F49;
              font-weight: 600;
            }
            &.end {
              color: #DC3545;
            }
          }
        }
        &.active {
          border-bottom: 1px solid #F7F9FC;
          background: #F7F9FC;
          .status {
            background: #ffffff;
          }
          &::after {
            opacity: 1;
            display: block;
          }
        }
        &::after {
          content: "";
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background: #076F49;
          transition: all .3s;
        }
        &:hover {
          border-bottom: 1px solid #F7F9FC;
          background: #F7F9FC;
          .status {
            background: #ffffff;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    height: 100%;
    .chat_top {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E0E4EA;
      padding-left: 20px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    .content {
      background: #F7F9FC;
      height: calc(100% - 120px);
      overflow: overlay;
      .subject {
        padding: 20px;
        background: #ffffff;
        li {
          margin-top: 16px;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          position: relative;

          .text {
            width: 80px;
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;
            color: #637381;
            &.topMove {
              margin-top: 5px;
            }
          }

          .assign_list {
            flex: 1;
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .assign_list_item {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 14px;
              font-weight: 600;
              color: rgba(46, 63, 79, 1);
              margin: 0 10px 0 0;
              white-space: nowrap;

              img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 4px;
              }

              span {
                max-width: 101px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

          .text_content {
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: #122545;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .createBox {
              display: flex;
              align-items: center;
            }
          }

          .status {
            padding: 4px 16px;
            background: #E3E9F1;
            font-size: 14px;
            line-height: 28px;
            font-weight: 500;
            color: #637381;
            border-radius: 50px;
            &.active {
              background: #076F49;
              color: #ffffff;
            }
            &.end {
              color: #DC3545;
            }
          }
        }

        li:nth-child(1) {
          margin-top: 0;
        }

        li:nth-child(2),
        li:nth-child(3) {
          margin-top: 8px;
        }
      }

      .record {
        padding: 16px 20px;

        .record_top {
          font-size: 14px;
          font-weight: 400;
          color: rgba(46, 63, 79, 0.8);
        }

        .item_log_list {
          margin-top: 11px;

          li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 17px;
            font-weight: 400;
            color: #757D8A;

            .item_user {
              display: flex;
              align-items: center;
              gap: 5px;
              width: 100%;
              img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }
              .item_user_text {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                flex: 1;
                margin-left: 5px;
                .item_user_name_box {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  line-height: 20px;
                  color: #122545;
                  font-weight: 500;
                  white-space: nowrap;
                  .item_user_name {
                    white-space: nowrap;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .button {
      padding: 12px 20px 16px 20px;
      width: 100%;
      text-align: right;
    }
  }
}
</style>
