<template>
  <div class="max_box">
    <div class="searchBoxMessage" id="searchBoxMessage">
      <div class="left">
        <div class="title">{{ $t('home.informationCenter') }}</div>
        <el-divider direction="vertical"></el-divider>
        <div class="navBox">
          <div 
            class="item" 
            v-for="item in navList"
            :key="item.id"
            :class="activeIndex == item.id ? 'active' : ''"
            @click="activeIndex = item.id"
          >
            <span>{{ $t(`InformationCenter.${item.name}`) }}</span>
            <span>{{ item.count > 99 ? '99+' : item.count  }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <el-input
          style="width: 117px"
          size="small"
          :placeholder="$t('home.PIorCINo')"
          v-model="searchForm.orderCI"
          clearable
          v-if="activeIndex != 0 && activeIndex != 4"
        />
        <chat-select
          style="width: 117px"
          size="small"
          v-model="searchForm.otherUsername"
          filterable
          clearable
          :placeholder="$t('home.People')"
          v-if="activeIndex != 4"
        >
          <el-option
            v-for="item in userList"
            :key="item.user && item.user.openId"
            :label="item.user && item.user.username"
            :value="item.user && item.user.username"
          >
          </el-option>
        </chat-select>
        <el-select
          style="width: 117px"
          size="small"
          v-model="searchForm.excludeRead"
          filterable
          clearable
          :placeholder="$t('home.Read / Unread')"
          v-if="activeIndex != 4"
        >
          <el-option
            v-for="item in optionsList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          style="width: 117px"
          size="small"
          v-model="searchForm.finish"
          filterable
          clearable
          :placeholder="$t('home.incomplete / Completed')"
          v-if="activeIndex == 1"
        >
          <el-option :label="$t('home.incomplete')" :value="false" />
          <el-option :label="$t('home.Completed')" :value="true" />
        </el-select>
        <el-date-picker
          style="width: 200px"
          size="small"
          type="daterange"
          v-model="searchForm.ListTime"
          :start-placeholder="$t('Dashboard.Startfrom')"
          :end-placeholder="$t('Dashboard.Endoftime')"
          :picker-options="pickerData"
          v-if="activeIndex != 0"
        />
        <el-button v-if="activeIndex == 0 || activeIndex == 4" class="sortBtn" size="small" @click="onSort()">
          {{ $t('InformationCenter.Sortby') }}
          <div class="sortIcon">
            <i class="el-icon-caret-top" :class="searchForm.sortType === 'asc' ? 'active' : ''"></i>
            <i class="el-icon-caret-bottom" :class="searchForm.sortType === 'desc' ? 'active' : ''"></i>
          </div>
        </el-button>
      </div>
    </div>
    <div class="roll_List" :style="`height: calc(100vh - ${searchHeight}px)`">
      <pingList v-if="activeIndex == 0" :form="searchFormPing" @updateCount="onMessageStat" />
      <todoList v-if="activeIndex == 1" :form="searchFormTodo" @updateCount="onMessageStat" />
      <commentList v-if="activeIndex == 2" :form="searchFormComment" @updateCount="onMessageStat" />
      <eventList v-if="activeIndex == 3" :form="searchFormEvent" @updateCount="onMessageStat" />
      <applyList v-if="activeIndex == 4" :form="searchFormApply" @updateCount="onMessageStat" />
    </div>
  </div>
</template>
<script>
import { pickerOptions } from "@/utils/utils";

import pingList from "./pingList.vue";
import applyList from "./applyList.vue";
import commentList from "./commentList.vue";
import eventList from "./eventList.vue";
import todoList from "./todoList.vue";

import { 
  messageStat
} from "@/api/news";
import { getCompanyEmployeeListAndRelation } from "@/api/comment";

import { mapGetters } from "vuex";

export default {
  name: "MessageList",
  components: {
    pingList,
    applyList,
    commentList,
    eventList,
    todoList,
  },
  data() {
    return {
      searchHeight: 0,
      activeIndex: 0,
      searchForm: {
        finish: "",
        otherUsername: "",
        excludeRead: "",
        sortType: "DESC",
        orderCI: "",
        relationType: "",
        ListTime: []
      },
      searchFormPing: null,
      searchFormTodo: null,
      searchFormComment: null,
      searchFormEvent: null,
      searchFormApply: null,
      userList: [],
      relationTypeList: [
        { label: this.$t("home.NOTICE"), value: "NOTICE" },
        { label: this.$t("home.OPERATION"), value: "OPERATION" },
      ],
      optionsList: [
        { label: this.$t('Dashboard.whole'), value: false },
        { label: this.$t('home.Unread'), value: true },
      ],
      navList: [{
        name: 'ping',
        id: 0,
        count: 0
      }, {
        name: 'Comment',
        id: 2,
        count: 0
      }, {
        name: 'Event',
        id: 3,
        count: 0
      }, {
        name: 'Todo',
        id: 1,
        count: 0
      }, {
        name: 'Apply',
        id: 4,
        count: 0
      }]
    };
  },
  computed: {
    ...mapGetters([
      "manageCompany", 
      'userInfo', 
      'chatMessage',
      "chatRead",
      "todoUpdate",
      "readTodo",
      "commentUpdate",
      "readComment",
      "eventUpdate",
      "readEvent",
      "applicationUpdate",
      "readApply",
      "commentAllRead",
      "eventAllRead"
    ]),
    pickerData() {
      return pickerOptions()
    },
  },
  watch: {
    userInfo: {
      handler() {
        this.navList = this.navList.splice(0, this.manageCompany ? 5 : 4);
      },
      deep: true,
      immediate: true
    },
    chatMessage() {
      this.onMessageStat()
    },
    chatRead() {
      this.onMessageStat()
    },
    commentUpdate() {
      this.onMessageStat()
    },
    readComment() {
      this.onMessageStat()
    },
    eventUpdate() {
      this.onMessageStat()
    },
    commentAllRead() {
      this.onMessageStat()
    },
    eventAllRead() {
      this.onMessageStat()
    },
    readEvent() {
      this.onMessageStat()
    },
    todoUpdate() {
      this.onMessageStat()
    },
    readTodo() {
      this.onMessageStat()
    },
    applicationUpdate() {
      this.onMessageStat()
    },
    readApply() {
      this.onMessageStat()
    },
    activeIndex() {
      this.searchForm = {
        finish: "",
        otherUsername: "",
        excludeRead: "",
        sortType: "DESC",
        orderCI: "",
        relationType: "",
        ListTime: []
      }
    },
    searchForm: {
      handler(val) {
        this.searchFormPing = null;
        this.searchFormTodo = null;
        this.searchFormComment = null;
        this.searchFormEvent = null;
        this.searchFormApply = null;
        if(this.activeIndex == 0) {
          this.searchFormPing = val;
        }
        if(this.activeIndex == 1) {
          this.searchFormTodo = val;
        }
        if(this.activeIndex == 2) {
          this.searchFormComment = val;
        }
        if(this.activeIndex == 3) {
          this.searchFormEvent = val;
        }
        if(this.activeIndex == 4) {
          this.searchFormApply = val;
        }
      },
      deep: true
    }
  },
  mounted() {
    const dom = document.getElementById("searchBoxMessage");
    const resizeObserver = new ResizeObserver((entries) => {
      this.searchHeight = entries[0].target.clientHeight+120;
    });
    resizeObserver.observe(dom);
    getCompanyEmployeeListAndRelation({
      includeSelf: true
    }).then((data) => {
      this.userList = data.list;
    });
    this.onMessageStat();
    const { type } = this?.$route?.query;
    if(type) {
      this.activeIndex = type;
    } else {
      this.activeIndex = 0;
    }
  },
  methods: {
    //时间排序
    onSort() {
      if (this.searchForm.sortType == 'asc') {
        this.searchForm.sortType = 'desc';
      } else {
        this.searchForm.sortType = 'asc';
      }
    },
    onMessageStat() {
      messageStat().then((data) => {
        this.navList[0].count = data.chat || 0;
        this.navList[3].count = data.todo || 0;
        this.navList[1].count = data.comment || 0;
        this.navList[2].count = data.event || 0;
        if(this.manageCompany) {
          this.navList[4].count = data.apply || 0;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.max_box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  .searchBoxMessage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12px;
    gap: 6px;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 16px;
        line-height: 22px;
        color: #122545;
        font-weight: 500;
      }
      .navBox {
        display: flex;
        gap: 24px;
        margin-left: 10px;
        .item {
          font-size: 14px;
          line-height: 28px;
          color: #637381;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 4px;
          transition: all .3s;
          &.active {
            font-weight: 600;
            color: #48AC42;
            span {
              &:first-child {
                transform: scale(1.1);
              }
              &:last-child {
                font-weight: 500;
                color: #ffffff;
                background: #DC3545;
              }
            }
          }
          span {
            transition: all .3s;
            &:last-child {
              display: inline-block;
              width: 22px;
              height: 16px;
              font-size: 12px;
              line-height: 14px;
              color: #757D8A;
              background: #E0E4EA;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px 4px 4px 0;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 10px;
      /deep/ .el-input__inner {
        border: none;
      }
    }
  }
  .roll_List {
    width: 100%;
    overflow: hidden;
    background: #ffffff;
    border-radius: 4px;
  }
}
.sortBtn {
  padding: 4px 8px;
  border: none;
  &:hover {
    background: #ffffff;
  }
  &:focus {
    background: #ffffff;
  }
  /deep/ span {
    display: flex;
    align-items: center;
    gap: 10px;
    .sortIcon {
      display: flex;
      flex-direction: column;
      i {
        &.active {
          color: #48AC42;
        }
      }
    }
  }
}

</style>
