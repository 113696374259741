<template>
  <div class="applyBox">
    <div class="chat_top">
      <div class="translateButton">
        <el-tabs 
          class="special_tabs" 
          v-model="title">
          <el-tab-pane :label="$t('InformationCenter.Company')" name="1" />
          <el-tab-pane :label="$t('InformationCenter.Personal')" name="2" />
        </el-tabs>
      </div>
    </div>
    <div 
      class="applyList" 
      v-loading="loading"
      v-infinite-scroll="load"
      :infinite-scroll-distance="20">
      <div class="item" v-for="(item, index) in applyList" :key="index">
        <!-- <span class="pending" v-if="item.approveStatus == 'APPROVING'" /> -->
        <template v-if="title === '1'">
          <div class="applyContent">
            <el-avatar :src="item.customer && item.customer.companyLogoUrl">u</el-avatar>
            <div class="content">
              <div class="content_text">
                <span>{{ item.customer && item.customer.aliasName }}</span>
                {{
                  item.result == '1' ?
                  (item.type == '0' ? $t('xiaoxie.Successfully established relationship') : $t("xiaoxie.Agreed to your cooperation invitation")) : 
                  item.result == '2' ? $t("InformationCenter.wantstoconnectwithyourcompany") : $t('home.Refuse to establish a relationship')
                }}
                <el-popover
                  placement="right"
                  :title="item.customer && item.customer.aliasName"
                  width="221"
                  trigger="click"
                  popper-class="customerApplyPopover">
                  <div class="inviteBox">
                    <div class="item" v-for="(row, idx) in item.customer.memberList" :key="idx">
                      <member :info="row" :size="48" />
                      <div class="content">
                        <div class="name">{{ row.nickName }}</div>
                        <div class="job">{{ row.position }}</div>
                      </div>
                    </div>
                  </div>
                  <a slot="reference">{{ $t('home.List of company personnel') }}</a>
                </el-popover>
              </div>
              <div class="remarkTimeBox">
                <div class="text" v-if="item.remark">{{ item.remark }}</div>
                <time>
                  {{ item.applyTime }}
                </time>
              </div>
            </div>
          </div>
          <div v-if="item.result == '2'" class="operation">
            <el-popconfirm
              :title="$t('home.youinvitation')"
              @confirm="operation('公司拒绝', item, index)"
            >
              <el-button slot="reference" size="small">
                {{ $t("InformationCenter.Refuse") }}
              </el-button>
            </el-popconfirm>
            <el-button size="small" type="primary" @click="operation('公司通过', item, index)">
              {{ $t("InformationCenter.Agree") }}
            </el-button>
          </div>
        </template>
        <template v-else>
          <div class="applyContent">
            <member :info="item.memberUser" :size="48" />
            <div class="content">
              <div class="content_text">
                <span>{{ item.memberUser && item.memberUser.nickName }}</span>
                {{ 
                  item.result == '2' ? 
                  $t("InformationCenter.wantstojoinyourcompany") : 
                  item.result == '1' ? $t('home.Member successfully joined') : 
                  $t('Refuse members to join') 
                }}
              </div>
              <div class="remarkTimeBox">
                <div class="text" v-if="item.remark">{{ item.remark }}</div>
                <time>
                  {{ item.applyTime }}
                </time>
              </div>
            </div>
          </div>
          <div v-if="item.result == '2'" class="operation">
            <el-popconfirm
              :title="$t('home.Whetherusercompany')"
              @confirm="operation('用户拒绝', item, index)"
            >
              <el-button slot="reference" size="small">
                {{ $t("InformationCenter.Refuse") }}
              </el-button>
            </el-popconfirm>
            <el-button size="small" type="primary" @click="operation('用户通过', item, index)">
              {{ $t("InformationCenter.Agree") }}
            </el-button>
          </div>
        </template>
      </div>
    </div>
    <assignApproveList 
      :approveListShow.sync="approveListShow1" 
      :customerCompanyId="customerCompanyId"
      @handleClose="handleClose" 
      :func="'Assign'"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import assignApproveList from "@/components/assignApproveList/assignApproveList";
import { approveCustomer, onApprove } from "@/api/customer";
import { companyApplyList } from "@/api/news";

export default {
  name: "applyList",
  components: { assignApproveList },
  data() {
    return {
      title: "1",
      loading: false,
      companyListTotal: 0,
      personnaTotal: 0,
      customerCompanyId: "",
      data: { 
        currentPage: 1,
        pageSize: 15,
      },
      totalPage: 0,
      applyList: [],
      approveListShow1: false
    };
  },
  computed: {
    ...mapGetters(["applicationUpdate", "readApply"]),
  },
  props: {
    form: {
      type: Object,
      default: {}
    }
  },
  watch: {
    form: {
      handler(val) {
        this.data = {
          ...this.data,
          currentPage: 1,
          startTime: val.ListTime?.length ? dayjs(val.ListTime[0]).format("YYYY-MM-DD HH:mm:ss") : "",
          endTime: val.ListTime?.length ? dayjs(val.ListTime[1]).format("YYYY-MM-DD HH:mm:ss") : ""
        }
        this.totalPage = 0;
        this.initialization();
      },
      deep: true
    },
    title() {
      this.initialization();
    },
    applicationUpdate() {
      this.initialization();
    },
    readApply() {
      this.initialization();
    }
  },
  mounted() {
    this.getList(true);
  },
  methods: {
    getList(clear) {
      this.loading = true;
      companyApplyList({
        ...this.data,
        type: this.title
      }).then((data) => {
        if(clear) this.applyList = [];
        this.applyList = this.applyList.concat(data.list);
        this.totalPage = data.pages;
      }).finally(() => {
        this.loading = false;
      })
    },
    operation(type, data, index) {
      switch (type) {
        case "公司通过":
          this.approveListShow1 = true;
          this.customerCompanyId = data.unid;
          break;
        case "公司拒绝":
          this.loadingCompany = true;
          approveCustomer({
            unid: data.unid,
            groupUsers: "",
            status: 3,
          }).then(() => {
            this.getList(true);
            this.$store.dispatch("user/setReadStatus", "READAPPLY");
            this.$message.success(this.$t('home.Refuse to establish a relationship'));
          }).finally(() => (this.loadingCompany = false));
          break;
        case "用户通过":
          this.loadingPersonnal = true;
          onApprove({
            unid: data.unid,
            status: 1,
          })
            .then(() => {
              this.getList(true);
              this.$store.dispatch("user/setReadStatus", "READAPPLY");
              this.$message.success(this.$t('home.Member successfully joined'));
            })
            .finally(() => (this.loadingPersonnal = false));
          break;
        case "用户拒绝":
          this.loadingPersonnal = true;
          onApprove({
            unid: data.unid,
            status: 3,
          })
            .then(() => {
              this.$message.success(this.$t('home.Refuse members to join'));
              this.$store.dispatch("user/setReadStatus", "READAPPLY");
              this.getList(true);
            })
            .finally(() => (this.loadingPersonnal = false));
          break;
      }
    },
    OpenDialogVisible(text, data, index) {
      // this.dialogText = text;
      // this.dialogData = data;
      // this.dialogIndex = index;
      // this.dialogVisible = true;
    },
    handleClose() {
      this.getList(true);
      this.approveListShow1 = false;
    },
    load() {
      if (this.data.currentPage < this.totalPage) {
        this.data.currentPage += 1;
        this.getList();
      }
    },
    initialization() {
      this.data.currentPage = 1;
      this.applyList = [];
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.applyBox {
  height: 100%;
  .chat_top {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E4EA;
    padding: 0 20px;
    .translateButton {
      height: 32px;
      /deep/ .special_tabs {
        background: #F7F9FC;
        .el-tabs__item {
          color: #757D8A;
          &.is-active {
            color: #122545;
          }
        }
        .el-tabs__active-bar {
          background: #E0E4EA;
        }
      }
    }
  }
  .applyList {
    padding: 20px 0;
    overflow: overlay;
    height: calc(100% - 60px);
    .item {
      padding: 16px 20px 20px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all .3s;
      cursor: pointer;
      &:hover {
        background: #F7F9FC;
      }
      .operation {
        display: flex;
        gap: 10px;
        .el-button {
          padding: 9px 25px;
        }
      }
      .applyContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0 0 4px;
        .el-avatar {
          width: 38px;
          height: 38px;
          border-radius: 4px;
        }
        .member-avatar {
          margin: 0;
        }
        .content {
          flex: 1;
          min-width: 0;
          overflow: hidden;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          .content_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #122545;
            // margin-bottom: 6px;
            a {
              color: #076F49;
              font-size: 14px;
              line-height: 20px;
              margin-left: 20px;
            }
          }
          .remarkTimeBox {
            display: flex;
            gap: 10px;
            .text {
              font-size: 12px;
              line-height: 17px;
              color: #757D8A;
            }
            time {
              font-size: 12px;
              line-height: 17px;
              color: #757D8A;
            }
          }
        }
      }
    }
  }
}
.inviteBox {
  max-height: 250px;
  overflow: overlay;
  padding-bottom: 16px;
  .item {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background: #F7F9FC;
    }
    .content {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      margin-left: 10px;
      .name {
        font-size: 14px;
        line-height: 20px;
        color: #122545;
        font-weight: 500;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
      }
      .job {
        font-size: 12px;
        line-height: 17px;
        color: #757D8A;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>

<style lang="less">
.customerApplyPopover {
  padding: 0;
  .el-popover__title {
    padding: 16px 16px 6px 16px;
  }
}
</style>
