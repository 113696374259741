<template>
  <div class="commentBox">
    <div class="left">
      <div
        class="commentList"
        v-loading="loading"
        v-infinite-scroll="load"
        :infinite-scroll-distance="20"
      >
        <div
          v-for="(item, index) in commentList"
          :key="index"
          class="item"
          :class="activeId == item.commentId ? 'active' : ''"
          @click="jump(item)"
        >
          <member :info="item.createPerson" :size="48" />
          <div class="content" >
            <div class="content_user">
              <span class="active" v-if="!item.read" />
              <span>
                {{ item.createPerson.nickName }}
                {{ $t("InformationCenter.Comments") }}
              </span>
              {{ item.contentCopy }}
            </div>
            <div class="bottom">
              <p>
                NO.{{ item.ci || item.pi }}
              </p>
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-empty -->
        <!-- v-else -->
        <!-- :description="$t('overallSituation.noData')" -->
      <!-- /> -->
    </div>
    <div class="right" v-if="commentData">
      <div class="chat_top">
        {{ $t('customerInfo.details') }}
        <span @click="goOrderDetail">{{ $t('home.seeMore') }}</span>
      </div>
      <div class="content">
        <div
          class="commentItem"
          dir="ltr"
          @click="goOrderDetail">
          <member
            :info="commentData.createPerson"
            :size="38"
          >
          </member>
          <div class="lmiWrapper">
            <div class="top">
              <div class="name">{{ commentData.createPerson && commentData.createPerson.nickName }}</div>
              <time>{{ commentData.createTime }}</time>
            </div>
            <conversation-bubble 
              :item="{
                msg: commentData.content,
                strId: commentData.eventId
              }" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { getCommentList, commentRead } from "@/api/event";
import { pickerOptions } from "@/utils/utils";
export default {
  name: "commentList",
  components: {},
  inject: ["reload"],
  data() {
    return {
      activeId: null,
      data: {
        currentPage: 1,
        pageSize: 15,
      },
      totalElement: 0,
      totalPage: 0,
      commentList: [],
      loading: true,
      commentData: null
    };
  },
  computed: {
    ...mapGetters(["commentUpdate", "commentAllRead"]),
    i18n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
  },
  mounted() {
    this.onGetList(this.data);
  },
  props: {
    form: {
      type: Object,
      default: {}
    }
  },
  watch: {
    form: {
      handler(val) {
        this.data = {
          ...this.data,
          currentPage: 1,
          username: val.otherUsername,
          startTime: val.ListTime?.length ? dayjs(val.ListTime[0]).format("YYYY-MM-DD HH:mm:ss") : "",
          endTime: val.ListTime?.length ? dayjs(val.ListTime[1]).format("YYYY-MM-DD HH:mm:ss") : "",
          piOrCi: val.orderCI,
          excludeRead: val.excludeRead || undefined
        }
        this.totalPage = 0;
        this.initialization();
      },
      deep: true
    },
    commentUpdate() {
      this.onGetList(true);
    },
    commentAllRead() {
      this.onGetList(true);
    }
  },
  methods: {
    initialization() {
      this.commentList.splice(0, this.commentList.length);
      if (this.data.currentPage == 1) {
        this.onGetList(true);
      } else {
        this.data.currentPage = 1;
      }
    },
    load() {
      if (
        this.data.currentPage < this.totalPage
      ) {
        this.data.currentPage += 1;
        this.onGetList();
      }
    },
    onGetList(clear = false) {
      this.loading = true;
      getCommentList(this.data)
        .then((data) => {
          if(clear) this.commentList = [];
          this.totalPage = data.pages;
          this.commentList = this.commentList.concat(data.list.map((res) => ({
            ...res,
            content: res.content,
            contentCopy: res.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, ""),
            createTime: dayjs(res.createTime).format("MM/DD HH:mm")
          })));
          this.activeId = this.commentList[0].commentId;
          this.commentData = this.commentList[0];
        })
        .finally(() => (this.loading = false));
    },
    jump(data) {
      if(!data.read) {
        commentRead(data.commentId).then(() => {
          data.read = true;
          this.$store.dispatch("user/setReadStatus", "READCOMMENT");
        });
      }
      this.activeId = data.commentId;
      this.commentData = data;
    },
    goOrderDetail() {
      this.reload(["OrderDetails"]);
      this.$router.push({
        path: "/order/orderDetails",
        query: {
          orderId: this.commentData.orderId,
          commentId: this.commentData.commentId
        },
      });
    }
  },
};
</script>
<style lang="less" scoped>
.commentBox {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .left {
    width: 25%;
    min-width: 300px;
    border-right: 1px solid #E0E4EA;
    .commentList {
      width: 100%;
      height: 100%;
      overflow: overlay;
      padding: 16px 0;
      .item {
        border-bottom: 1px solid #E0E4EA;
        transition: all .3s;
        cursor: pointer;
        position: relative;
        padding: 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .content {
          flex: 1;
          min-width: 0;
          overflow: hidden;
          margin-left: 10px;
          .content_user {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            .active {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #DC3545;
              margin-right: 4px;
            }
            span {
              color: #757D8A;
              font-weight: 400;
            }
            color: #122545;
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 17px;
            margin-top: 4px;
            p {
              color: #076F49;
              width: 60%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            span {
              color: #A3B0C6;
              white-space: nowrap;
            }
          }
        }
        &.active {
          border-bottom: 1px solid #F7F9FC;
          background: #F7F9FC;
          .status {
            background: #ffffff;
          }
          &::after {
            opacity: 1;
            display: block;
          }
        }
        &::after {
          content: "";
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 2px;
          height: 100%;
          background: #076F49;
          transition: all .3s;
        }
        &:hover {
          border-bottom: 1px solid #F7F9FC;
          background: #F7F9FC;
          .status {
            background: #ffffff;
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    .chat_top {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E0E4EA;
      padding: 0 20px;
      font-size: 14px;
      line-height: 20px;
      color: #122545;
      font-weight: 500;
      span {
        color: #076F49;
        font-weight: 400;
        cursor: pointer;
      }
    }
    .content {
      padding: 16px 20px 20px 16px;
      .commentItem {
        display: flex;
        justify-content: space-between;
        .lmiWrapper {
          flex: 1;
          min-width: 0;
          margin-left: 14px;
          .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            font-size: 12px;
            line-height: 17px;
            .name {
              color: #122545;
              font-weight: 500;
            }
            time {
              color: #757D8A;
            }
          }
        }
      }
    }
  }
}
</style>
